export interface PageOptions {
    pageType: string
    pageName: string
    pageCategory: string
    pageLocation: string
    pageTitle: string
    pageReferrer: string
}

export function getFirstPathSegment(url: string) {
    try {
        const sanitizedUrl = encodeURI(url)
        const parsedUrl = new URL(sanitizedUrl)
        const pathSegments = parsedUrl.pathname.split('/').filter((segment) => segment.length > 0)
        return pathSegments.length > 0 ? '/' + pathSegments[0] : '/'
    } catch (error) {
        throw new Error('Invalid URL')
    }
}

export function getPageOptionsBasedOnPath(route: string): PageOptions {
    const pageLocation = route
    const pageTitle = document.title
    const pageReferrer = document.referrer ?? ''
    switch (route) {
        case '/':
            return {
                pageType: 'home',
                pageName: 'Home Page',
                pageCategory: 'homepage',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        case '/categories':
            return {
                pageType: 'list',
                pageName: 'Categories',
                pageCategory: 'categories',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        case '/create':
            // Here we will probably add all rules for create sub-routes
            return {
                pageType: 'content_creation',
                pageName: 'Create',
                pageCategory: 'gifmaker',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        case '/upload':
            // Here we will probably add all rules for create sub-routes
            return {
                pageType: 'content_creation',
                pageName: 'Upload',
                pageCategory: 'upload',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        case '/clips':
            return {
                pageType: 'detail',
                pageName: 'Clips',
                pageCategory: 'clip',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        case '/dashboard':
            return {
                pageType: 'content_creation',
                pageName: 'Creator Dashboard',
                pageCategory: 'dashboard',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        case '/explore':
            return {
                pageType: 'list',
                pageName: 'Explore',
                pageCategory: 'content',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        case '/favorites':
            return {
                pageType: 'list',
                pageName: 'Favorites',
                pageCategory: 'content',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        case '/gifs':
            return {
                pageType: 'detail',
                pageName: 'GIFs',
                pageCategory: 'gif',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        case '/stickers':
            return {
                pageType: 'detail',
                pageName: 'Stickers',
                pageCategory: 'sticker',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        case '/login':
            return {
                pageType: 'form',
                pageName: 'Log In',
                pageCategory: 'login',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        case '/apply':
            return {
                pageType: 'form',
                pageName: 'Apply',
                pageCategory: 'creator_signup',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        case '/join':
            return {
                pageType: 'form',
                pageName: 'Join',
                pageCategory: 'signup',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        case '/password':
            return {
                pageType: 'form',
                pageName: 'Password',
                pageCategory: 'password_recovery',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        case '/email-jail':
            return {
                pageType: 'email',
                pageName: 'Email Jail',
                pageCategory: 'email_jail',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        case '/search':
            return {
                pageType: 'list',
                pageName: 'Search',
                pageCategory: 'search',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        case '/studios':
            return {
                pageType: 'channel',
                pageName: 'GIPHY Studios',
                pageCategory: 'studios_channel',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        case '/team':
            return {
                pageType: 'staff',
                pageName: 'Team',
                pageCategory: 'team',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        case '/edit/gifs':
            return {
                pageType: 'content_creation',
                pageName: 'Edit GIFs',
                pageCategory: 'edit_gifs',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        case '/artists':
            return {
                pageType: 'list',
                pageName: 'Artists',
                pageCategory: 'artists',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        case '/apps':
            return {
                pageType: 'list',
                pageName: 'Apps',
                pageCategory: 'apps',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        case '/transparency':
            return {
                pageType: 'legal',
                pageName: 'Transparency',
                pageCategory: 'transparency',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        case '/about':
            return {
                pageType: 'about',
                pageName: 'About',
                pageCategory: 'about',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        case '/ar-scenes':
            return {
                pageType: 'list',
                pageName: 'AR Scenes',
                pageCategory: 'ar_scenes',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        case '/stories':
            return {
                pageType: 'detail',
                pageName: 'Stories',
                pageCategory: 'story',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        case '/posts':
            return {
                pageType: 'list',
                pageName: 'Posts',
                pageCategory: 'posts',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        case '/settings':
            return {
                pageType: 'form',
                pageName: 'Settings',
                pageCategory: 'settings',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        case '/trending-gifs':
            return {
                pageType: 'list',
                pageName: 'Trending GIFs',
                pageCategory: 'trending_gifs',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        case '/edit':
            return {
                pageType: 'content_creation',
                pageName: 'Edit',
                pageCategory: 'edit',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        case '/404':
            return {
                pageType: 'error',
                pageName: '404 Not found',
                pageCategory: 'error',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        case '/403':
            return {
                pageType: 'error',
                pageName: '403 Forbidden',
                pageCategory: 'error',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        case '/maintenance':
            return {
                pageType: 'error',
                pageName: 'Maintenance',
                pageCategory: 'error',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
        default:
            // Here we will probably add all rules for channels pages
            return {
                pageType: 'channel',
                pageName: 'Channels',
                pageCategory: 'channel',
                pageLocation,
                pageTitle,
                pageReferrer,
            }
    }
}
